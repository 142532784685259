<template>
  <div class="dropdown-list-container">
    <div class="heading">
      <p>{{ category }}</p>
    </div>
    <div class="select-list-body">
      <VueNextSelect
        :closeOnSelect="true"
        :modelValue="defaultSelected"
        @update:modelValue="onOptionSelected"
        valueBy="value"
        :options="optionValues"
        style="font-family: Jost, sans-serif"
      >
        <template v-slot:label="{ selected }">
          <template v-if="selected">
            <div class="selected-dropdown-list-item">
              <span class="text">{{ selected.label }}</span>
            </div>
          </template>
          <template v-if="!selected">
            <span class="text-default"> {{ defaultText }}</span></template
          >
        </template>
        <template v-slot:dropdown-item="{ option }">
          <div class="dropdown-list-item">
            <span class="text-main">{{ option.value }}</span>
          </div>
        </template>
      </VueNextSelect>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import VueNextSelect from 'vue-next-select';
import 'vue-next-select/dist/index.min.css';

export default defineComponent({
  name: 'WidgetDropDownObjects',
  components: {
    VueNextSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: Object,
    },
    defaultText: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  emits: ['update:selected'],
  setup(props, { emit }) {
    const defaultSelected = ref(props.defaultValue?.value ?? null);
    const selected = props.defaultValue;
    const optionValues = ref(props.options);

    const onOptionSelected = (evt: any) => {
      emit('update:selected', evt);
    };

    watch(
      () => props.options,
      newOptions => {
        optionValues.value = newOptions;
      },
      { deep: true },
    );

    return {
      selected,
      defaultSelected,
      optionValues,
      onOptionSelected,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/_include-media.scss';
@import '../../assets/scss/_colors.scss';

.text {
  font-family: Jost, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 7px;

  &-default {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #bdbdbd;
    margin-left: 12px;
  }
}

.dropdown-list-container {
  & .select-container:focus {
    outline: none !important;
  }

  & .menu .select-list-body {
    width: 100% !important;
  }

  & .select-container {
    outline: none !important;
    display: flex;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #9e9e9e;

    @include media('<tablet') {
      height: 54px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  & .heading {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin: 0px -20px -6px 0px;
    @include media('<tablet') {
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
    }
  }

  & .vue-select {
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px;
    border-color: #e0e0e0;

    &.direction-bottom {
      & .vue-dropdown {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-color: #e0e0e0;
      }
    }
  }

  & .vue-select-header {
    height: 54px;
    cursor: pointer !important;
    line-height: 18px;
    color: $brand-black;
    font-size: 16px;

    & > .vue-input {
      margin-left: 0;
      cursor: pointer !important;
      font-weight: 500;
      color: $brand-grey-500 !important;
      font-size: 12px;

      & > .selected-dropdown-list-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > span {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 5px;
          color: $brand-black;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    & > .icon.arrow-downward {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  & .vue-dropdown {
    z-index: 1000;

    &-item {
      font-family: Jost, 'sans-serif';
      color: $brand-black;
      font-weight: 400;
      font-size: 12px !important;
      margin-left: 0;
      height: 30px;
      line-height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > .dropdown-list-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > img {
          height: 100%;
        }

        & > span {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }

      &.highlighted {
        background-color: $brand-white !important;
      }

      &.selected {
        background-color: $brand-white !important;
      }

      &:hover {
        background-color: $brand-grey-300 !important;
      }
    }
  }
}

.dropdown-list-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  & > span {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 6px;
  }
}
</style>
