<template>
  <div class="checkbox-form-2">
    <div class="answers2">
      <label class="item">
        <span></span>
        <input
          class="textCheckbox"
          type="checkbox"
          id="other"
          :checked="boxChecked"
          @click="toggleTextInput"
        />
        <span class="checkmark" />
        <div id="inputCheckbox">
          <input
            :class="{ textInput: true, 'textInput-error': error }"
            type="text"
            v-model="textInput"
            :disabled="inputDisabled"
            :placeholder="placeholder"
            @input="inputChanged(textInput)"
            @blur="$emit('blur', true)"
            @focus="$emit('focus', true)"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-dupe-keys */
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'InputCheckbox',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: 'Other',
    },
    boxChecked: {
      type: Boolean,
    },
    defaultText: {
      type: String,
    },
    rules: {
      type: Function,
      default: () => true,
    },
  },
  emits: ['boxChecked', 'textInput', 'blur', 'focus'],
  setup(props, { emit }) {
    const inputDisabled = ref(!props.boxChecked ?? true);
    const boxChecked = ref(props.boxChecked ?? false);
    const textInput = ref(props.defaultText ?? '');
    const error = ref(undefined);

    const inputChanged = (input: string) => {
      textInput.value = input;
      emit('textInput', input);
    };
    const toggleTextInput = () => {
      inputDisabled.value = !inputDisabled.value;
      emit('boxChecked', !inputDisabled.value);
    };

    /* eslint-disable no-unused-expressions */
    watch(textInput, value => {
      const ruleError = props.rules(value || '');
      ruleError === true
        ? (error.value = undefined)
        : (error.value = ruleError);

      if (!value && ruleError === true) {
        error.value = undefined;
      }
    });

    return {
      inputDisabled,
      textInput,
      boxChecked,
      inputChanged,
      toggleTextInput,
      error,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/colors';

.checkbox-form-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#inputCheckbox ::placeholder {
  color: #bdbdbd;
  font-family: Jost, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.checkbox-form-2 .answers2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  font-family: Jost, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  padding-top: 14px;
}

.textInput {
  width: 75%;
  height: 24px;
  gap: 12px;
  border: 1px solid #e0e0e0;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  padding: 16px;
  border-radius: 16px;
  margin-top: -30px;
  font-family: Jost, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.textInput:focus {
  outline: none;
  border: 1px solid $brand-secondary-purple;
}

.textInput-error {
  border: 1px solid #b00020 !important;
  border-radius: 12px;
}

.checkbox-form-2 label {
  margin-left: 1em;
}

.checkbox-form-2 .item {
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  width: 100%;
  height: 20px;
}

.checkbox-form-2 .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.checkbox-form-2 .item:hover input ~ .checkmark {
  background-color: $brand-secondary-purple;
}

.checkbox-form-2 .item input:checked ~ .checkmark {
  background-color: $brand-secondary-purple;
}

.checkbox-form-2 .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-form-2 .item input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-form-2 .item .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
