<template>
  <div class="body">
    <StepsCard :step="step">
      <template #step>
        {{ steps }}
      </template>
      <template #body>
        <div class="heading-text">
          <h4>{{ heading }}</h4>
        </div>
        <div class="caption">
          <p>{{ subtext }}</p>
        </div>

        <div v-if="element === 'inputs'" class="text-input-container">
          <div class="input-container">
            <Input
              label="Email address"
              type="text"
              v-model="v.email"
              name="email"
              :rules="rules.email"
            />
          </div>
          <div class="input-container">
            <Input
              label="Password"
              type="password"
              v-model="v.password"
              name="password"
              :rules="rules.password"
            />
          </div>
        </div>

        <div v-if="element === 'codeinput'" class="text-input-container">
          <div class="input-container">
            <CodeInput :amount="6" v-model="v.password" :rules="rules.code" />
          </div>
        </div>

        <div v-if="element === 'searchInput'" class="text-input-container">
          <div class="input-container">
            <SearchInput
              name="search"
              v-model="v.password"
              placeholder="Search something..."
              :rules="rules.valid"
              @startSearch="buttonClicked()"
            />
          </div>
        </div>

        <div v-if="element === 'passwordPrompt'" class="text-input-container">
          <div class="input-container">
            <Input
              label="Password"
              type="password"
              v-model="v.password"
              name="password"
              :rules="rules.password"
            />
          </div>
          <div class="input-container">
            <PasswordPrompt :rules="passwordRules" />
          </div>
        </div>

        <div v-if="element === 'dialog'" class="text-input-container">
          <div class="input-container">
            <Dialog
              :open="openDialog"
              :width="550"
              icon="https://staging--yellowcard-webapp.netlify.app/img/USDT.a424a757.svg"
              title="I am a Dialog"
              subtitle="I pop up and display awesome stuff 😊"
              @close-dialog="openDialog = false"
            />
          </div>
          <div class="input-container">
            <Button :text="buttonText" @buttonClicked="openDialog = true" />
          </div>
        </div>

        <div v-if="element !== 'dialog'" class="button-container">
          <div>
            <Button
              :text="buttonText"
              :radius="10"
              @buttonClicked="buttonClicked()"
              style="width: 360px"
              :disabled="isButtonDisabled"
            />
          </div>
        </div>

        <Snackbar
          v-if="toast.show"
          :text="toast.message"
          :position="toast.position"
          @dismissed="toastDismiss"
        />

        <p>&nbsp;</p>
      </template>
    </StepsCard>
  </div>
</template>

<script lang="ts">
import validator from 'validator';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import Button from '../Button/Button.vue';
import Input from '../Input/Input.vue';
import CodeInput from '../CodeInput/CodeInput.vue';
import StepsCard from '../StepsCard/StepsCard.vue';
import SearchInput from '../SearchInput/SearchInput.vue';
import PasswordPrompt from '../PasswordPrompt/PasswordPrompt.vue';
import Dialog from '../Dialog/Dialog.vue';
import Snackbar from '../Snackbar/Snackbar.vue';

export default defineComponent({
  name: 'SampleModal',
  props: {
    heading: {
      type: String,
    },
    step: {
      type: Number,
    },
    steps: {
      type: String,
    },
    subtext: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'Submit',
    },
    element: {
      type: String,
      default: 'inputs',
    },
  },
  components: {
    StepsCard,
    Input,
    Button,
    CodeInput,
    SearchInput,
    PasswordPrompt,
    Dialog,
    Snackbar,
  },
  setup(props) {
    const v = reactive({
      email: '',
      password: '',
    });
    const toast = reactive({
      show: false,
      message: '',
      position: 'top-right',
    });
    const openDialog = ref(false);
    const passwordRules = ref<any>([]);

    const rules = computed(() => ({
      code: (value: string) => {
        if (validator.isEmpty(value)) return 'Code is required';
        if (validator.isLength(value, { min: 1, max: 5 }))
          return 'Code is required';
        return true;
      },
      email: (value: string) => {
        if (validator.isEmpty(value)) return 'Your email address is required';
        if (!validator.isEmail(value))
          return 'Please provide a valid email address';
        return true;
      },
      valid: () => true,
      password: (value: string) => {
        if (validator.isEmpty(value)) return 'Your password is required';
        return true;
      },
    }));

    const passwordRulesDefine = (password: string) => [
      { enabled: /.{8,}/.test(password), label: 'At least 8 characters' },
      { enabled: /[A-Z]+/.test(password), label: 'Uppercase letter (A-Z)' },
      { enabled: /[a-z]+/.test(password), label: 'Lowercase letter (a-z)' },
      { enabled: /[\W_]+/.test(password), label: 'Special character (#,*)' },
      { enabled: /[0-9]+/.test(password), label: 'Number (0-9)' },
    ];

    const buttonClicked = () => {
      toast.show = true;
      toast.message = 'Button clicked';
    };

    const isButtonDisabled = ref(true);

    const toastDismiss = () => {
      toast.show = false;
    };

    watch(v, value => {
      const email = props.element !== 'inputs' ? 'kode@mail.com' : v.email;
      isButtonDisabled.value = !(
        rules.value.email(email) === true &&
        rules.value.code(value.password) === true &&
        rules.value.password(value.password) === true
      );
      passwordRules.value = passwordRulesDefine(value.password);
    });

    onMounted(() => {
      passwordRules.value = passwordRulesDefine(v.password);
    });

    return {
      v,
      toast,
      rules,
      openDialog,
      passwordRules,
      toastDismiss,
      buttonClicked,
      isButtonDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.body {
  background: $brand-secondary-purple-25;
  padding: 20px;
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.check {
  width: 150px;
}

.text-input-container {
  width: 90%;
}

.caption {
  position: relative;
  top: -25px;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}

.heading-text {
  position: relative;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.input-container {
  padding-left: 50px;
  padding-bottom: 20px;
}

.button-container {
  padding-left: 5px;
  padding-top: 70px;
  text-align: center;
}

.small-text {
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9e9e9e;
  padding-top: 5px;
}
</style>
