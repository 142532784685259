<template :id="root">
  <button
    :class="`${root} ${root}__${color} __${variant} __${size} __${
      block ? 'block' : ''
    } __br${radius}`"
    :disabled="isDisabled"
    @click="$emit('buttonClicked', true)"
  >
    {{ !loader ? text : '' }}
    <div class="loader" v-if="loader"></div>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'purple',
      validator: (val: string) =>
        ['purple', 'yellow', 'black', 'success', 'danger'].includes(val),
    },
    size: {
      type: String,
      default: 'large',
      validator: (val: string) => ['small', 'medium', 'large'].includes(val),
    },
    variant: {
      type: String,
      default: 'fill',
      required: false,
      validator: (val: string) => ['fill', 'outline'].includes(val),
    },
    block: {
      type: Boolean,
      default: true,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: 4,
    },
    disabled: Boolean,
  },
  setup(props) {
    const isDisabled = computed(() => props.disabled || props.loader);

    return {
      isDisabled,
    };
  },
  data: () => ({
    root: 'yc--button',
  }),
});
</script>

<style lang="scss">
@import '../../assets/scss/colors';

.yc--button {
  font-family: Jost, sans-serif;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.__br10 {
    border-radius: 10px;
  }

  &.__br4 {
    border-radius: 4px;
  }

  &.__br72 {
    border-radius: 72px;
  }

  &.__block {
    width: 100%;
  }

  &.__large {
    font-size: 16px;
    font-weight: 500;
    height: 56px;
    padding: 0 20px;
  }

  &.__medium {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    padding: 0 16px;
  }

  &.__small {
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    padding: 0 13px;
  }

  &__yellow {
    color: $brand-black !important;
    border: 1px solid $brand-primary-yellow !important;
    background: $brand-primary-yellow !important;

    &.__outline {
      color: $brand-primary-yellow-500 !important;
      border: 1px solid $brand-primary-yellow-500 !important;
      background: #fff !important;
    }

    &:hover {
      color: $brand-black !important;
      border: 1px solid $brand-primary-yellow-500 !important;
      background: $brand-primary-yellow-500 !important;
    }
  }

  &__purple {
    color: #fff !important;
    border: 1px solid $brand-secondary-purple !important;
    background: $brand-secondary-purple !important;

    &.__outline {
      color: $brand-secondary-purple !important;
      border: 1px solid $brand-secondary-purple !important;
      background: #fff !important;
    }

    &:hover {
      color: #fff !important;
      border: 1px solid $brand-secondary-purple-600 !important;
      background: $brand-secondary-purple-600 !important;
    }
  }

  &__black {
    border: 1px solid $brand-black !important;
    background: $brand-black !important;

    &.__outline {
      color: $brand-black !important;
      border: 1px solid $brand-black !important;
      background: #fff !important;
    }

    &:hover {
      color: #fff !important;
      border: 1px solid $brand-black !important;
      background: $brand-black !important;
    }
  }

  &__success {
    color: #fff !important;
    border: 1px solid $status-success !important;
    background: $status-success !important;

    &.__outline {
      color: $status-success !important;
      border: 1px solid $status-success !important;
      background: #fff !important;
    }

    &:hover {
      color: #fff !important;
      border: 1px solid $status-success !important;
      background: $status-success !important;
    }
  }

  &__danger {
    color: #fff !important;
    border: 1px solid $status-error !important;
    background: $status-error !important;

    &.__outline {
      color: $status-error !important;
      border: 1px solid $status-error !important;
      background: #fff !important;
    }

    &:hover {
      color: #fff !important;
      border: 1px solid $status-error !important;
      background: $status-error !important;
    }
  }

  &:disabled {
    color: #bdbdbd !important;
    background: #f5f5f5 !important;
    cursor: not-allowed !important;
  }

  .loader {
    border: 3px solid #cccccc;
    border-top: 3px solid $brand-secondary-purple;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    justify-content: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
