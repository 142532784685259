<template>
  <div class="heading">
    <p>{{ category }}</p>
  </div>
  <div class="select-list-body">
    <VueNextSelect
      :closeOnSelect="true"
      :modelValue="defaultSelected"
      @selected="onOptionSelected"
      :placeholder="text"
      :options="optionValues"
      style="font-family: Jost, sans-serif"
    >
      <template v-slot:dropdown-item="{ option }">
        <span class="text-main">{{ option }}</span>
      </template>
    </VueNextSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import VueNextSelect from 'vue-next-select';
import 'vue-next-select/dist/index.min.css';

export default defineComponent({
  name: 'DropDownList',
  components: {
    VueNextSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: [String, Boolean, Number],
    },
    defaultText: {
      type: String,
      required: false,
      default: 'Show all',
    },
    category: {
      type: String,
      require: true,
    },
  },
  emits: ['update:selected'],
  setup(props, { emit }) {
    const selected = ref('');
    const defaultSelected = props.defaultValue;
    const text = props.defaultText;
    const optionValues = props.options;
    const selectedValue = '';

    const onOptionSelected = (evt: any) => {
      emit('update:selected', evt);
    };

    return {
      selected,
      defaultSelected,
      text,
      optionValues,
      selectedValue,
      onOptionSelected,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/_include-media.scss';
@import '../../assets/scss/_colors.scss';

.select-container:focus {
  outline: none !important;
}

.menu .select-list-body {
  width: 100% !important;
}

.select-container {
  outline: none !important;
  display: flex;
  background: #ffffff;
  border: none !important;
  border-radius: 4px;
  flex: none;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9e9e9e;

  > option {
    font-family: 'Jost', sans-serif;
  }

  @include media('<tablet') {
    height: 46px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.heading {
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin: 0px -20px -6px 0px;
  @include media('<tablet') {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }
}

.vue-select {
  width: 100% !important;
}

.vue-select-header {
  height: 38px;
  cursor: pointer !important;
  //font-family: Jost, 'sans-serif';
  line-height: 22px;
  color: $brand-black;
  font-size: 16px;

  > .vue-input {
    margin-left: 16px;
    cursor: pointer !important;
    //font-family: Jost, 'sans-serif';
    font-weight: 500;
    color: $brand-grey-500 !important;
    font-size: 12px;

    > input {
      cursor: pointer !important;
      //font-family: Jost, 'sans-serif';
      color: $brand-grey-500 !important;
    }
  }

  > .icon.arrow-downward {
    margin-right: 16px;
  }
}

.vue-dropdown {
  z-index: 1000;

  &-item {
    font-family: Jost, 'sans-serif';
    color: $brand-grey-900;
    font-size: 12px !important;
    line-height: 22px;
    margin-left: 0px;
    height: 24px;
    line-height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.highlighted {
      background-color: $brand-white !important;
    }

    &.selected {
      background-color: $brand-white !important;
    }

    &:hover {
      background-color: $brand-grey-300 !important;
    }
  }
}
</style>
