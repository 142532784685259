<template>
  <VueDatePicker
    v-model="date_picked"
    :range="pickRange"
    :multi-calendars="pickRange"
    :enable-time-picker="false"
    calendar-cell-class-name="yc_calendar_cell"
    @update:model-value="modelValueUpdated"
    @open="onDatePickerOpen"
    placeholder="Select Dates"
    select-text="Apply"
    cancel-text="Cancel"
  >
  </VueDatePicker>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineComponent({
  name: 'DatePicker',
  props: {
    pickRange: {
      type: Boolean,
      required: false,
      default: true,
    },
    datePicked: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    VueDatePicker,
  },
  emits: ['rangeSelected', 'datePickerOpen'],
  setup(props, { emit }) {
    const date_picked = ref(props.datePicked);
    const startDate = ref('');
    const endDate = ref('');
    const selectedDate = ref('');

    const onDatePickerOpen = () => {
      emit('datePickerOpen');
    };

    const modelValueUpdated = (modelData: any) => {
      if (modelData === null) {
        startDate.value = '';
        endDate.value = '';
      } else if (props.pickRange) {
        const startDateObj = new Date(modelData[0]);
        const endDateObj = new Date(modelData[1]);

        startDate.value = startDateObj.toDateString();
        endDate.value = endDateObj.toDateString();
      } else {
        selectedDate.value = new Date(modelData).toDateString();
      }

      emit('rangeSelected', {
        startDate: startDate.value,
        endDate: endDate.value,
        selectedDate: selectedDate.value,
      });
    };

    return {
      date_picked,
      startDate,
      endDate,
      modelValueUpdated,
      onDatePickerOpen,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/_colors.scss';

.yc_calendar_cell {
  border-radius: 20px;
  font-family: 'Jost', sans-serif;

  & :hover {
    background-color: $brand-secondary-purple-100;
  }
}

.dp {
  &__menu {
    height: auto;
  }

  &__today {
    background-color: $brand-secondary-purple-25;
    color: #000 !important;
    border: $brand-secondary-purple-25;
  }

  &__instance_calendar {
    font-family: 'Jost', sans-serif;
  }

  &__calendar_header_item {
    font-family: 'Jost', sans-serif;
  }

  &__active_date {
    background-color: $brand-secondary-purple-25;
    color: #000 !important;
  }

  &__input_wrap {
    > input {
      font-family: 'Jost', sans-serif;
      color: $brand-grey-300;
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__range {
    &_start {
      background-color: $brand-secondary-purple-400 !important;
      color: #fff !important;
    }

    &_end {
      background-color: $brand-secondary-purple-400 !important;
      color: #fff !important;
    }

    &_between {
      background-color: $brand-grey-100;
    }
  }

  &__cancel {
    border: 1px solid $brand-secondary-purple;
    text-align: center;
    color: $brand-secondary-purple !important;
    width: 50%;
    justify-content: center;
    height: 30px;
    margin-right: 4px;
    font-weight: 500;
  }

  &__select {
    border: 1px solid $brand-secondary-purple;
    background-color: $brand-secondary-purple;
    text-align: center;
    color: #fff !important;
    font-weight: normal;
    width: 50%;
    justify-content: center;
    height: 30px;
    margin-left: 4px;
    font-weight: 500;
  }

  &__action {
    &_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Jost', sans-serif;
    }
  }

  &__selection {
    &_preview {
      font-size: 1.1rem;
      padding-top: 5px;
      font-family: 'Jost', sans-serif;
    }
  }
}
</style>
