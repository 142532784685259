<template>
  <div class="switch-pill-container" :class="{ disabled: isDisabled }">
    <div class="switch-pill-wrapper">
      <div
        v-for="(item, index) in optionValues"
        :key="index"
        :class="
          item === defaultSelected
            ? 'switch-pill-option active'
            : 'switch-pill-option'
        "
        @click="onOptionClicked"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SwitchPill',
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['option:selected'],
  setup(props, { emit }) {
    const defaultSelected = props.defaultValue;
    const optionValues = props.options;
    const isDisabled = props.disabled;

    /* eslint-disable no-restricted-syntax */
    const onOptionClicked = (evt: any) => {
      if (!evt.target.classList.contains('active')) {
        const parent = evt.target.parentElement;
        for (const option of parent.children) {
          option.classList.remove('active');
        }
        evt.target.classList.add('active');
        emit('option:selected', evt.target.innerText);
      }
    };

    return {
      defaultSelected,
      optionValues,
      isDisabled,
      onOptionClicked,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/_colors.scss';

.switch-pill {
  &-container {
    font-family: Jost, 'sans-serif';
    width: 250px;
    height: 50px;

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &-wrapper {
    width: 100%;
    height: 36px;
    border: 1px solid $brand-grey-200;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 32px;
    padding: 8px;
    background-color: $brand-grey-50;
  }

  &-option {
    color: $brand-grey-400;
    background-color: $brand-white;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 24px;
    text-transform: capitalize;
    cursor: pointer;
    background-color: $brand-grey-50;

    &.active {
      background-color: $brand-secondary-purple;
      color: $brand-white;
    }
  }
}

@media screen and (max-width: 700px) {
  .switch-pill {
    &-container {
      width: 200px;
      height: 50px;

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    &-wrapper {
      border-radius: 24px;
      height: 36px;
      padding: 8px;
    }

    &-option {
      border-radius: 24px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
