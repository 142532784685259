<template>
  <div class="tab" :class="active === true ? 'active' : ''">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tab',
  props: {
    active: Boolean,
  },
});
</script>

<style lang="scss">
.tab {
  display: none;
}

.tab.active {
  display: block;
}
</style>
